import { isMobileDevice, TEAM_ROLES, USER_ROLES } from 'encirclelabs-shared';

// screens
const commonScreens = [
  '/projects',
  '/profile',
  '/projects/:projectId',
  '/requests',
  '/company',
  '/analytics',
  '/platform-settings',
  '/services',
];

const defaultSceenACL = {
  available: ['/profile', '/projects', '/projects/:projectId', '/company'],
  default: '/projects',
};
// map screens to team role
export const screensACL = isMobileDevice
  ? {
      [TEAM_ROLES.PaymentManager]: defaultSceenACL,
      [TEAM_ROLES.Admin]: defaultSceenACL,
      [TEAM_ROLES.CustomerSuccess]: defaultSceenACL,
      [TEAM_ROLES.Superuser]: defaultSceenACL,
    }
  : {
      [TEAM_ROLES.PaymentManager]: {
        available: commonScreens,
        default: '/requests',
      },
      [USER_ROLES.Admin]: {
        available: commonScreens,
        default: '/requests',
      },
      [TEAM_ROLES.CustomerSuccess]: {
        available: commonScreens,
        default: '/requests',
      },
      [USER_ROLES.Superuser]: {
        available: commonScreens,
        default: '/requests',
      },
    };
